import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
    <Layout>
        <SEO title="Home"/>
        <h1>Distribuidora de Gelo</h1>
        <p>
            A Distribuidora de Gelo é uma empresa especializada na distribuição de gelo em
            São Paulo. Atendendo pessoas físicas e jurídicas, a fornecedora trabalha com sacos
            de gelo 5 kg e sacos de gelo 10 kg britados.
        </p>
        <p>
            A distribuição de gelo no modelo delivery de gelo 24 horas surgiu da crescente necessidade de acesso ao
            produto, mas dificuldade de encontrá-lo facilmente a pronta-entrega em diferentes regiões de São Paulo.
        </p>
        <p>
            Devido à inovação no modelo de distribuição de gelo proposta pela Distribuidora de Gelo ter que sair no meio
            de eventos para buscar gelo ou interromper festividades devido à falta de bebidas geladas e conservação de
            alimentos não é mais uma necessidade.
        </p>
        <p>
            O cliente tem acesso prático e ágil à entrega de gelo, sem precisar deslocar-se no complicado trânsito
            paulista, molhar o carro para buscar o produto ou comprometer parte do gelo durante o deslocamento.
        </p>
        <h2>Tipos de distribuição de gelo</h2>
        <p>
            Pensando nesses cenários indesejados, a Distribuidora de Gelo oferece diferentes tipos de distribuição de
            gelo para melhor atender clientes físicos e jurídicos, como:
        </p>
        <ul>
            <li>
                entrega programada de gelo na qual o cliente solicita com antecedência o local e horário que deseja que
                o gelo seja entregue considerando quantidade e tipo de gelo que vai precisar;
            </li>
            <li>
                entrega emergencial de gelo que atenda situações nas quais o estoque inicial de gelo não foi suficiente,
                precisando de um reabastecimento do produto durante o evento.
            </li>
        </ul>
        <p>
            Esses dois tipos de entrega de gelo estão disponíveis para eventos familiares ou de médio e grande porte,
            sendo que a Distribuidora de Gelo atende: hotéis, restaurantes, bares, casas noturnas, buffets, shows e
            outros tipos de eventos e confraternizações.
        </p>
        <h2>Quais os tipos de gelo entregues?</h2>
        <p>
            A Distribuidora de Gelo trabalha com diferentes tipos do produto para garantir maior praticidade aos
            clientes, como:
        </p>
        <ul>
            <li>
                sacos de gelo 5 kg;
            </li>
            <li>
                sacos de gelo 10 kg.
            </li>
        </ul>
        <p>
            O gelo britado garante o uso tanto no resfriamento e conservação de alimentos e bebidas, como o uso direto
            no produto consumido.
        </p>
        <p>
            Esse tipo de uso é possível, pois os gelos da Distribuidora de Gelo são produzidos utilizando água potável e
            com
            processo certificados pelo Controle Hídrico do Estado de São Paulo (Cohesp).
        </p>
        <p>
            O armazenamento do gelo na distribuidora de gelo SP é realizado em câmeras frigoríficas com temperaturas de
            até - 15° C para melhor conservação do gelo.
        </p>
        <p>
            Outra característica é que o produto sai do centro de distribuição de gelo da empresa em veículos especiais
            com isolamento térmico para garantir a conservação até entrega do gelo ao cliente final.
        </p>
        <h2>Distribuidora de Gelo na Vila Mariana e região</h2>
        <p>
            A Distribuidora de Gelo está localizada na região da Vila Mariana, próxima ao Parque Ibirapuera. Dessa
            forma, a fornecedora de gelo consegue atender com mais rapidez e agilidade a entrega de gelo nas
            proximidades.
        </p>
        <p>
            Além da distribuição de gelo na Vila Mariana, a Distribuidora de Gelo atua em vários bairros do município de
            São Paulo, como: Paraíso, Jardins, Jardim Paulista, Jardim Paulistano, Cerqueira César, Moema, Campo Belo,
            Pinheiros, Faria Lima, Higienópolis, Aclimação, Itaim, Saúde, Sumaré, Perdizes, Lapa, Vila Madalena,
            Morumbi, Liberdade e Bela Vista.
        </p>
        <p>
            Destaca-se que, devido à proximidade com o centro de distribuição da fábrica de gelo, a entrega nesses
            bairros é mais rápida e ágil.
        </p>
        <p>
            Caso esteja em outras localidades na cidade de São Paulo ou região metropolitana é possível consultar a
            disponibilidade do delivery de gelo 24 horas diretamente nos telefones de contato da Distribuidora de Gelo.
        </p>

    </Layout>
)

export default IndexPage
